import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { CustomMaterialModule } from './custom-material/custom-material.module';
import { AppRoutingModule } from './app-routing.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LoaderService } from './core/services/loader.service';
import { StoreService } from './core/services/store.service';
import { RequestService } from './core/services/request.service';
import { LayoutUtilsService } from './core/services/layout-utils.service';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UserActivityService } from './core/services/userActivity.service';
import { RollbarService, rollbarFactory, GlobalErrorHandler } from './core/services/globar-error.handler';
import { FirebaseHandlersService } from './core/services/firebase-handlers.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/', '.json');
};

// Function to initialize Firebase before app starts
export function initializeFirebase(firebaseService: FirebaseHandlersService) {
  return () => firebaseService.initialize();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    CustomMaterialModule.forRoot(),
    AppRoutingModule,
    NgxPermissionsModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    LoaderService,
    StoreService,
    RequestService,
    LayoutUtilsService,
    UserActivityService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    FirebaseHandlersService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeFirebase,
      multi: true,
      deps: [FirebaseHandlersService],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
