<div class="chart-title">{{'Action Outcomes' | translate}}</div>
<div style="padding: 2vh 1vw">
    <ng-container *ngIf="!loading">
        <apx-chart *ngIf="pieChartOptions" [series]="pieChartOptions.pieSeries" [chart]="pieChartOptions.chart"
            [labels]="pieChartOptions.labels" [xaxis]="pieChartOptions.xaxis" [matTooltip]="pieChartOptions.title">
        </apx-chart>
        <span *ngIf="!pieChartOptions">{{'No data yet.' | translate}}</span>
    </ng-container>
    <div *ngIf="loading" style="height: 100%;">
        <mat-spinner [diameter]="20" style="margin: 20% auto auto auto;"></mat-spinner>
    </div>
</div>