import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { SpinnerService } from '../../core/services/spinner.service';
import { NavigationEnd, Router } from '@angular/router';
import { FirebaseHandlersService } from 'src/app/core/services/firebase-handlers.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { RequestService } from 'src/app/core/services/request.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    selector: 'app-shelter-layout',
    templateUrl: './shelter-layout.component.html',
    styleUrls: ['./shelter-layout.component.scss']
})
export class ShelterLayoutComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('snav') sidenav!: MatSidenav;

    // private _mobileQueryListener: () => void;
    isTablet: boolean = false;
    isMobile: boolean = false;
    showSpinner: boolean = false;
    userName: string = "";
    isAdmin: boolean = false;
    currentRoute: string = '';
    now = new Date();
    profileImage: string = '';
    currentNotification: any = undefined;
    notificationTimer: any = undefined;
    // unreadNotifications: number = 0;
    currentUser: any = undefined;

    private subscriptions: any[] = <any>[];
    private autoLogoutSubscription: Subscription = new Subscription;

    notificationAudio: any = undefined;

    @ViewChild('notification') set playerRef(ref: ElementRef<HTMLAudioElement>) {
        if (ref)
            this.notificationAudio = ref.nativeElement;
    }

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private deviceService: DeviceDetectorService,
        public spinnerService: SpinnerService, private requestService: RequestService, private loadService: LoaderService,
        private router: Router, private firebaseHandler: FirebaseHandlersService, private notificationService: NotificationService) {
        this.isTablet = this.deviceService.isTablet();
        this.isMobile = this.deviceService.isMobile();
        // initializeApp(environment.firebaseConfig);
        // this.mobileQuery = this.media.matchMedia('(max-width: 1000px)');
        // this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        // this.mobileQuery.addListener(this._mobileQueryListener);

        router.events.subscribe(data => {
            if (data instanceof NavigationEnd)
                this.currentRoute = data.url;
        });
    }

    ngOnInit(): void {
        this.subscriptions.push(this.requestService.currentUserSubject.subscribe((user) => {
            if (user) {
                this.currentUser = user;

                if (!user.shelter?._id)
                    this.requestService.logout();
                else {
                    // this.firebaseHandler.topic = 'admittance-' + user.shelter._id;
                    // this.fbHandler.requestPermission(this.fbHandler.topic);

                    this.isAdmin = user.isAdmin;
                    this.userName = user.name;
                    this.profileImage = user.pictureLink || 'assets/images/profile.png';

                    // this.firebaseHandler.registerDevice(this.currentUser._id);

                    // this.firebaseHandler.listen();
                }
            }
        }));

        this.subscriptions.push(this.notificationService.showShelterNotification.subscribe((message: any) => {
            // console.log('message', message)
            if (message) {
                if (this.notificationAudio) {
                    this.notificationAudio.load();
                    this.notificationAudio.loop = 0;
                    this.notificationAudio.volume = 0.25;
                    this.notificationAudio.play().catch((error: any) => { });
                }
                this.currentNotification = message.notification.body;
                if (this.notificationTimer) {
                    clearTimeout(this.notificationTimer);
                    this.notificationTimer = undefined;
                }
                this.notificationTimer = setTimeout(() => this.currentNotification = null, 10000);
            }
            else {
                this.currentNotification = null;
            }
            //     if (message?.notification?.body) {
            //         if (this.notificationAudio) {
            //             this.notificationAudio.load();
            //             this.notificationAudio.loop = 0;
            //             this.notificationAudio.volume = 0.25;
            //             this.notificationAudio.play();
            //         }

            //         // let notification: any = message.notification.body;

            //         if (this.currentRoute == '/center/admittance-requests') {
            //             localStorage.removeItem('notifications');
            //             this.requestService.reloadAdmittance.next(true);
            //         }

            //         this.unreadNotifications = JSON.parse(localStorage.getItem('notifications') || '0');

            //         this.unreadNotifications = this.unreadNotifications + 1;
            //         localStorage.setItem('notifications', JSON.stringify(this.unreadNotifications));

            //         // this.currentNotification = this.notificationService.generateShelterNotification(notification.homeless_name, notification.beds_remaining);
            //         this.currentNotification = message.notification.body;

            //         if (this.notificationTimer) {
            //             clearTimeout(this.notificationTimer);
            //             this.notificationTimer = undefined;
            //         }
            //         this.notificationTimer = setTimeout(() => this.currentNotification = null, 10000);
            //     }
            //     else
            //         this.currentNotification = null;
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
        // tslint:disable-next-line: deprecation
        // this.mobileQuery.removeListener(this._mobileQueryListener);
        this.autoLogoutSubscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    closeNotification() {
        this.currentNotification = null;
    }

    // onLoggedout() {
    //     this.loadService.display(true);
    //     this.requestService.logOutApi();
    // }

    goTo(whereTo: string) {
        if (this.isMobile || this.isTablet)
            this.sidenav.close();
        this.router.navigate([whereTo]);
    }

    logout() {
        this.loadService.display(true);
        this.firebaseHandler.logout();
    }
}
