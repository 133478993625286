<div class="navbar-container">
  <mat-sidenav-container class="navbar-sidenav-container">
    <mat-sidenav #snav [opened]="!isTablet && !isMobile" [mode]="isTablet || isMobile ? 'over' : 'side'"
      [fixedInViewport]="isTablet || isMobile" fixedTopGap="0" class="sidenav">
      <div fxLayout="column" fxLayoutGap="0" fxLayoutAlign="start center" style="height: 100%;">
        <div fxFlex class="menu" [class.selected]="currentRoute === '/'" fxGrow="0">
          <mat-icon mat-list-icon (click)="snav.toggle()">
            menu_open
          </mat-icon>
        </div>
        <div fxFlex class="icon-container" [class.selected]="currentRoute === '/center'" fxGrow="0">
          <a (click)="goTo('/center')" [matTooltip]="'Profile' | translate">
            <mat-icon mat-list-icon>
              night_shelter
            </mat-icon>
          </a>
        </div>
        <div fxFlex class="icon-container" [class.selected]="currentRoute === '/center/admittance-requests'" fxGrow="0">
          <a (click)="goTo('/center/admittance-requests')" [matTooltip]="'Admittance Requests' | translate">
            <mat-icon mat-list-icon>
              admin_panel_settings
            </mat-icon>
          </a>
        </div>
        <div fxFlex class="icon-container" [class.selected]="currentRoute.indexOf('/center/lookup') !== -1" fxGrow="0">
          <a (click)="goTo('/center/lookup')" [matTooltip]="'Lookup' | translate">
            <mat-icon mat-list-icon>
              person_search
            </mat-icon>
          </a>
        </div>
        <div fxFlex class="icon-container" fxLayoutAlign="center end" fxGrow="1">
          <a (click)="logout()" [matTooltip]="'Logout' | translate">
            <mat-icon mat-list-icon style="font-size: 20pt">
              exit_to_app
            </mat-icon>
            <!-- <span>{{'Log Out' | translate}}</span> -->
          </a>
        </div>
      </div>
    </mat-sidenav>
    <!-- Main content -->
    <mat-sidenav-content class="sidenav-content">
      <button mat-icon-button (click)="snav.toggle()" class="menu"
        [style.visibility]="snav.opened ? 'hidden' : 'unset'">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-toolbar class="navbar" fxLayout="column">
        <div fxFlex class="logo">
          <img src="/assets/images/CCIS logo.png" />
        </div>
        <div fxFlex class="top-section">
          <div fxLayout="column" class="title">
            <h1 fxFlex>CCIS</h1>
            <div class="subtitle" fxFlex>{{ now | date: 'fullDate'}}</div>
          </div>
          <span class="navbar-spacer"></span>
        </div>
      </mat-toolbar>
      <app-loading-screen></app-loading-screen>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<div class="notification-drawer" *ngIf="currentNotification">
  <mat-icon class="close" (click)="closeNotification()">close</mat-icon>
  <div class="body">
    {{currentNotification}}
  </div>
</div>
<!-- 
<div class="notification" *ngIf="currentNotification">
  <mat-icon (click)="closeNotification()">close</mat-icon>
  <div *ngIf="unreadNotifications > 0" class="header">You have {{unreadNotifications}} new notification<span
      *ngIf="unreadNotifications > 1">s</span></div>
  <div class="text">{{currentNotification}}</div>
</div> -->
<audio #notification src="assets/files/notification.mp3" preload="auto" autostart="0"></audio>