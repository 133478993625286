<div fxLayout="row" fxLayoutGap="1vh" fxLayoutGap.xs="1vw" fxLayout.xs="column">
    <div fxFlex>
        <app-action-outcomes-chart [actionTypes]="historyTypeEnum" [memberId]="memberId"
            *ngIf="memberId && token && historyTypeEnum?.length" [token]="token" [orgId]="orgId">
        </app-action-outcomes-chart>
    </div>
    <div fxFlex>
        <app-blood-content-chart></app-blood-content-chart>
    </div>
    <div fxFlex>
        <app-contanine-level-chart></app-contanine-level-chart>
    </div>
</div>