<div class="col-xl-12">
	<!-- <div mat-dialog-title class="dialog-title">
		<div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
			<div fxFlex class="filterName">
				{{data.title}}
			</div>
		</div>
	</div> -->
	<div mat-dialog-content class="dialog-content">
		<div fxLayout="column" fxLayoutGap="1vh">
			<div fxFlex fxLayout="row" fxLayoutGap="2vw" class="tag-container">
				<div fxFlex>
					<div class="yellow tag">{{'Safety Concern' | translate}}</div>
				</div>
				<div fxFlex class="detail">
					{{'May be violent. Police presence required.' | translate}}
				</div>
			</div>
			<div fxFlex fxLayout="row" fxLayoutGap="2vw" class="tag-container middle">
				<div fxFlex>
					<div class="blue tag">{{'Substance Use' | translate}}</div>
				</div>
				<div fxFlex class="detail">
					{{'Heavy Opioid Use.' | translate}}
				</div>
			</div>
			<div fxFlex fxLayout="row" fxLayoutGap="2vw" class="tag-container">
				<div fxFlex>
					<div class="grey tag">{{'Health Issues' | translate}}</div>
				</div>
				<div fxFlex class="detail">

				</div>
			</div>
			<div fxFlex fxLayout="row" fxLayoutGap="2vw" class="tag-container">
				<div fxFlex>
					<div class="brown tag">{{'High Utilizer' | translate}}</div>
				</div>
				<div fxFlex class="detail">

				</div>
			</div>
			<div fxFlex fxLayout="row" fxLayoutGap="2vw" class="tag-container">
				<div fxFlex>
					<div class="pink tag">{{'Suicidal / Self Harm' | translate}}</div>
				</div>
				<div fxFlex class="detail">

				</div>
			</div>
			<div fxFlex fxLayout="row" fxLayoutGap="2vw" class="tag-container">
				<div fxFlex>
					<div class="green tag">{{'Serious Mental Health Crisis' | translate}}</div>
				</div>
				<div fxFlex class="detail">

				</div>
			</div>
		</div>
	</div>
	<div mat-dialog-actions align="center">
		<div class="kt-form__actions kt-form__actions--sm">
			<button mat-raised-button (click)="onNoClick()" class="button">{{'Close' | translate}}</button>&nbsp;
		</div>
	</div>
	<br />
</div>