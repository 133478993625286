import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { initializeApp } from 'firebase/app';
import { onMessage, getMessaging, getToken, isSupported } from "firebase/messaging";
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LayoutUtilsService } from './layout-utils.service';
import { NotificationService } from './notification.service';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseHandlersService {

  currentMessage = new BehaviorSubject(null);
  token: string = '';
  // topic = 'notification';
  firebaseApp: any = undefined;

  constructor(private requestService: RequestService, private notificationService: NotificationService, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService) {
    if (window.location.pathname.indexOf('/registered-members/charts') == -1) {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', event => {
          // console.log('event', event)
          if (event.data.data?.shelter == 'false') {
            this.currentMessage.next(event.data);
            this.notificationService.showNotificationUnRead.next(true);
          }
          else
            this.notificationService.showShelterNotification.next(event.data.data);
        });
      }

      if (Notification.permission === 'granted') {
        // try to subscribe
        // console.error('The user has blocked notifications.');
      } else if (Notification.permission === 'denied') {
        this.layoutUtilsService.showNotification(this.translate.instant('Notifications were blocked by the browser. Enable to receive notifications.'), 'Dismiss');
        // console.error('The user has blocked notifications.');
      } else {
        // The user has not yet responded to the permission request
      }
    }
  }

  public async initialize() {
    if (window.location.pathname.indexOf('/registered-members/charts') == -1)
      if (await isSupported()) {
        this.firebaseApp = initializeApp(environment.firebaseConfig);
        // this.requestPermission();
        // this.listen(this.firebaseApp);
      }
  }

  public requestPermission(topic: string, callback?: any) {
    const messaging = getMessaging();
    getToken(messaging,
      { vapidKey: environment.firebaseVapidKey }).then(
        (currentToken) => {
          this.token = currentToken;
          this.requestService.subscribeFirebaseTopic(topic, currentToken, (data, error) => {
            if (callback)
              callback();
            // console.log(data, error);
            this.listen(this.firebaseApp);
          });
          if (currentToken) {
            // console.log(currentToken);
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
  }

  public registerDevice(currentUserId: string, callback?: any) {
    if (Notification.permission === 'granted') {
      if (currentUserId) {
        const messaging = getMessaging();
        getToken(messaging,
          { vapidKey: environment.firebaseVapidKey }).then(
            (currentToken) => {
              this.token = currentToken;
              this.requestService.postRequest('notify/register', {
                userId: currentUserId,
                type: "Web",
                deviceId: this.token
              }, (data, error) => {
                if (callback)
                  callback();
              });
            });
      }
    }
    else if (Notification.permission === 'default') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          this.registerDevice(currentUserId, callback);
        } else {
          console.log('Notification permission is denied');
        }
      });
    }
  }

  listen(firebaseApp: any = this.firebaseApp) {
    const messaging = getMessaging(firebaseApp);
    onMessage(messaging, (payload: any) => {
      // console.log('Message received. ', payload);
      this.currentMessage.next(payload);
    });
  }

  sendNotificationToDevice(responderId: string, payload: any) {
    this.requestService.postRequest('notify/firebase/' + responderId + '/send', payload, (data, error) => {

    });
  }

  sendNotificationToType(type: string, payload: any) {
    this.requestService.postRequest('notify/firebase/' + type, payload, (data, error) => {

    });
  }

  unsubscribeTopic(topic: string, callback: any): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.token && topic)
        this.requestService.postRequest('notify/unsubscribe/topic', {
          deviceToken: this.token,
          topic: topic
        }, (data, error) => {
          if (data)
            resolve(data);
          else if (error)
            reject(error);

          callback();
        });
    });
  }

  unregisterDevice(userId: string, deviceId: string, callback: any): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.token)
        this.requestService.postRequest('notify/device/delete', {
          userId: userId,
          deviceId: deviceId,
          type: "Web"
        }, (data, error) => {
          if (data)
            resolve(data);
          else if (error)
            reject(error);

          callback();
        });
      else
        resolve(undefined);
    });
  }

  async logout() {
    // if (this.requestService.currentUser?.shelter) {
    if (this.token)
      this.unregisterDevice(this.requestService._currentUser._id, this.token, () => {
        this.requestService.logOutApi();
      });
    else
      this.requestService.logOutApi();
    // }
    // else {
    //   this.unsubscribeTopic(this.topic, () => {
    //     this.requestService.logOutApi();
    //   })
    // }
  }
}