import { Component, OnInit, OnDestroy } from "@angular/core";
import { ApexAxisChartSeries, ApexNonAxisChartSeries, ApexChart, ApexXAxis, ApexTitleSubtitle, ApexGrid, ApexPlotOptions, ApexResponsive } from "ng-apexcharts";

export type ChartOptions = {
    series: ApexAxisChartSeries;
    pieSeries: ApexNonAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    title: ApexTitleSubtitle;
    grid: ApexGrid;
    plotOptions: ApexPlotOptions;
    labels: any;
    responsive: ApexResponsive[];
};

@Component({
    selector: 'app-blood-content-chart',
    templateUrl: './blood-content-chart.component.html',
    styleUrls: ['./blood-content-chart.component.scss']
})
export class BloodContentChartComponent implements OnInit, OnDestroy {

    public barChartOptions!: Partial<ChartOptions>;

    constructor() { }

    ngOnInit(): void {
        this.barChartOptions = {
            series: [
                {
                    name: "My-series",
                    data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
                }
            ],
            chart: {
                height: 250,
                type: "line"
            },
            xaxis: {
                categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
            }
        };
    }

    ngOnDestroy(): void {

    }
}