<div class="navbar-container">
  <!-- Top navbar -->
  <mat-sidenav-container class="navbar-sidenav-container">
    <!-- Side nav -->
    <mat-sidenav #snav [opened]="!isTablet && !isMobile" [mode]="isTablet || isMobile ? 'over' : 'side'"
      [fixedInViewport]="isTablet || isMobile" fixedTopGap="0" class="sidenav">
      <div fxLayout="column" fxLayoutGap="0" fxLayoutAlign="center center">
        <div fxFlex class="icon-container" [class.selected]="currentRoute === '/'">
          <a [routerLink]="['']">
            <mat-icon mat-list-icon [matTooltip]="'Home' | translate" matTooltipClass="tooltip">
              home
            </mat-icon>
          </a>
        </div>
        <div fxFlex class="icon-container" [class.selected]="currentRoute.indexOf('/registered-members') !== -1">
          <a [routerLink]="['registered-members']">
            <mat-icon mat-list-icon [matTooltip]="'Community Members' | translate" matTooltipClass="tooltip">
              people
            </mat-icon>
          </a>
        </div>
        <div fxFlex class="icon-container" [class.selected]="currentRoute.indexOf('/insights') !== -1">
          <a [routerLink]="['insights']">
            <mat-icon mat-list-icon [matTooltip]="'Insights' | translate" matTooltipClass="tooltip">
              insights
            </mat-icon>
          </a>
        </div>
        <div fxFlex class="icon-container" [class.selected]="currentRoute.indexOf('/notifications') !== -1">
          <a [routerLink]="['notifications']">
            <mat-icon mat-list-icon [matTooltip]="'Notifications' | translate" matTooltipClass="tooltip">
              notifications
            </mat-icon>
          </a>
        </div>
        <div fxFlex class="icon-container" [class.selected]="currentRoute === '/registered-responders'">
          <a [routerLink]="['registered-responders']">
            <img src="./assets/images/responder-icon.png" style="height: 50px;" [matTooltip]="'Responders' | translate"
              matTooltipClass="tooltip" />
            <!-- <mat-icon mat-list-icon>
              local_police
            </mat-icon> -->
          </a>
        </div>
        <div fxFlex class="icon-container" [class.selected]="currentRoute === '/centers'">
          <a [routerLink]="['centers']">
            <mat-icon mat-list-icon [matTooltip]="'Centers' | translate" matTooltipClass="tooltip">
              night_shelter
            </mat-icon>
          </a>
        </div>
      </div>
    </mat-sidenav>
    <!-- Main content -->
    <mat-sidenav-content class="sidenav-content">
      <mat-toolbar class="navbar">
        <div fxLayout="column" class="title">
          <h1 fxFlex>{{'CCIS' | translate}}</h1>
          <div class="subtitle" fxFlex>{{ now | date: 'fullDate'}}</div>
        </div>
        <span class="navbar-spacer"></span>
        <div style="position: relative;">
          <button mat-icon-button [routerLink]="['/notifications']">
            <mat-icon class="notifications">notifications<div *ngIf="showNotificationUnRead" class="unread"></div>
            </mat-icon>
          </button>
          <div class="notification-drawer" *ngIf="currentNotification">
            <mat-icon class="close" (click)="closeNotification()">close</mat-icon>
            <div class="body">
              {{currentNotification}}
            </div>
            <div class="tag-container" *ngIf="flags?.length">
              <div class="tag" [class.blue]="flag === 'substance_use'" [class.yellow]="flag === 'safety_concern'"
                [class.grey]="flag === 'health_issues'" [class.grey]="flag._id === 'health_issues'"
                [class.brown]="flag._id === 'high_utilizer'" [class.pink]="flag._id === 'suicidal_selfharm'"
                [class.green]="flag._id === 'serious_mental_health_crisis'" [matTooltip]="flag | enum:flagEnum"
                *ngFor="let flag of flags">
              </div>
            </div>
          </div>
        </div>
        <div class="profile-image">
          <img [src]="profileImage" onerror="this.src='assets/images/user.png';" />
        </div>
        <span fxShow fxHide.xs class="username">
          {{userName}}
        </span>
        <button mat-icon-button [matMenuTriggerFor]="userMenu">
          <mat-icon class="expand-more">expand_more</mat-icon>
        </button>
        <mat-menu #userMenu="matMenu" xPosition="before" yPosition="above" [overlapTrigger]="false">
          <a mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Log out</span>
          </a>
        </mat-menu>
      </mat-toolbar>
      <app-loading-screen></app-loading-screen>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>