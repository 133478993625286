import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RequestService } from "src/app/core/services/request.service";

@Component({
    selector: 'app-member-chart',
    templateUrl: './member-chart.component.html',
    styleUrls: ['./member-chart.component.scss']
})
export class MemberChartComponent implements OnInit, OnDestroy {

    public historyTypeEnum: any = [];
    public memberId: string = '';
    public token: string = '';
    public orgId: string = '';

    private subscriptions: any[] = <any>[];

    constructor(private requestService: RequestService, private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
        this.subscriptions.push(this.activatedRoute.params.subscribe((params: any) => {
            this.memberId = params.id;
            this.token = params.token;
            this.orgId = params.orgId;
            this.requestService.group = params.group;

            this.requestService.getRecordByToken('metadata', 'actions', this.token ? this.token : this.requestService.getToken(), (data, error) => {
                if (data?.results?.fields) {
                    this.historyTypeEnum = data.results.fields.find((i: any) => i.name === 'action').enum;
                }
            });
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}