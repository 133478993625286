import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LayoutUtilsService } from 'src/app/core/services/layout-utils.service';
import { RequestService } from 'src/app/core/services/request.service';

@Component({
	selector: 'app-password-dialog-modal',
	templateUrl: './custom-password-dialog.component.html',
	styleUrls: ['./custom-password-dialog.component.scss']
})
export class ModalPasswordDialogComponent implements OnInit {
	public errorMessage: string = '';
	public loading: boolean = false;
	public showOldPassword: boolean = false;
	public showNewPassword: boolean = false;
	public showConfirmPassword: boolean = false;
	public toVerify: boolean = false;
	public email: string = '';
	public passwordForm!: FormGroup;

	constructor(
		private requestService: RequestService, private translate: TranslateService,
		private layoutUtilsService: LayoutUtilsService, private formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<ModalPasswordDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		// console.log('ModalPasswordDialogComponent', data);
		if (data.toVerify) {
			this.toVerify = true;
		}
		if (data.email) {
			this.email = data.email;
		}
	}

	ngOnInit() {
		if (this.toVerify) {
			this.passwordForm = this.formBuilder.group({
				oldpassword: ['', [Validators.required]],
				password: ['', [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")]],
				confirmpassword: ['', [Validators.required, this.checkPasswords]]
			});
		} else {
			this.passwordForm = this.formBuilder.group({
				oldpassword: ['', [Validators.required, this.compareOldNewPasswords]],
				password: ['', [Validators.required, this.compareOldNewPasswords, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")]],
				confirmpassword: ['', [Validators.required, this.checkPasswords]]
			});
		}
	}

	private compareOldNewPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
		let pass = this.passwordForm?.controls['password'].value;
		let oldPass = this.passwordForm?.controls['oldpassword'].value;
		return pass === oldPass && pass?.length > 0 && oldPass?.length > 0 ? { same: true } : null;
	}

	private checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
		let pass = this.passwordForm?.controls['password'].value;
		let confirmPass = this.passwordForm?.controls['confirmpassword'].value;
		if (pass === confirmPass || (confirmPass?.length === 0 || pass?.length === 0))
			return null;
		else {
			return { notSame: true };
		}
	}

	public saveData() {
		if (this.passwordForm.controls['password'].value !== '' && this.passwordForm.controls['oldpassword'].value !== '') {
			if (this.passwordForm.controls['password'].value === this.passwordForm.controls['confirmpassword'].value) {
				if (this.passwordForm.controls['password'].value.length < 8 || this.passwordForm.controls['password'].value.length > 64) {
					this.layoutUtilsService.showNotification('Password should be of at least 8 characters and maximum 64 characters', this.translate.instant('Dismiss'));
					return;
				}
				if (!this.loading) {
					this.loading = true;
					this.errorMessage = '';
					if (this.toVerify) {
						this.requestService.confirmUserPassword(this.email, this.passwordForm.controls['oldpassword'].value, this.passwordForm.controls['password'].value, (data: any, error: any) => {
							if (error) {
								this.errorMessage = error;
								if (error.toLowerCase().includes('invalid user')) {
									this.passwordForm.controls['oldpassword'].setErrors({ 'incorrect': true });
								}
								else
									this.layoutUtilsService.showNotification(error, this.translate.instant('Dismiss'));
							}
							this.loading = false;
							if (data) {
								// this.layoutUtilsService.showNotification(this.translate.instant('Password Changed'), this.translate.instant('Dismiss'));
								this.closeModal(true);
							}
						});
					} else {
						this.requestService.editUserPassword(this.passwordForm.controls['oldpassword'].value, this.passwordForm.controls['password'].value, (data, error) => {
							if (error) {
								this.errorMessage = error;
								if (error.toLowerCase().includes('invalid user')) {
									this.passwordForm.controls['oldpassword'].setErrors({ 'incorrect': true });
								}
								else
									this.layoutUtilsService.showNotification(error, this.translate.instant('Dismiss'));
							}
							this.loading = false;
							if (data) {
								this.layoutUtilsService.showNotification(this.translate.instant('Password Changed'), this.translate.instant('Dismiss'));
								this.closeModal(true);
							}
						});
					}
				}
			} else {
				this.layoutUtilsService.showNotification(this.translate.instant('The passwords don\'t match'), this.translate.instant('Dismiss'));
			}
		} else {
			this.layoutUtilsService.showNotification(this.translate.instant('Fill in the fields'), this.translate.instant('Dismiss'));
		}
	}

	closeModal(data: any): void {
		this.dialogRef.close(data);
	}
}
