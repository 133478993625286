import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CustomMaterialModule } from '../custom-material/custom-material.module';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ContentPlaceholderAnimationComponent } from './content-placeholder-animation/content-placeholder-animation.component';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { LayoutComponent } from './layout/layout.component';
import { UpdateStatusDialogComponent, FetchEntityDialogComponent, DeleteEntityDialogComponent, ErrorEntityDialogComponent, AlertEntityDialogComponent, AlertActionEntityDialogComponent, ActionNotificationComponent, ConfirmSelectEntityDialogComponent, ModalPasswordDialogComponent, ConfirmInputEntityDialogComponent, ModalCropDialogComponent } from './modals';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { ConfirmFileEntityDialogComponent } from './modals/confirm-file-entity-dialog/confirm-file-entity-dialog.component';
import { EnumPipe } from './pipes/enum.pipe';
import { TagDialogComponent } from './modals/tag-dialog/tag-dialog.component';
import { ShelterLayoutComponent } from './shelter-layout/shelter-layout.component';
import { CustomImageCropperDialogComponent } from './image-cropper-dialog/image-cropper-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
    imports: [
        RouterModule,
        CustomMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        TranslateModule,
        ImageCropperModule
    ],
    declarations: [
        ConfirmDialogComponent,
        ContentPlaceholderAnimationComponent,
        LimitToPipe,
        LocalDatePipe,
        EnumPipe,
        YesNoPipe,
        LayoutComponent,
        ShelterLayoutComponent,
        UpdateStatusDialogComponent,
        FetchEntityDialogComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        AlertEntityDialogComponent,
        AlertActionEntityDialogComponent,
        ActionNotificationComponent,
        ConfirmSelectEntityDialogComponent,
        ModalPasswordDialogComponent,
        ConfirmInputEntityDialogComponent,
        ModalCropDialogComponent,
        LoadingScreenComponent,
        ConfirmFileEntityDialogComponent,
        TagDialogComponent,
        CustomImageCropperDialogComponent
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        CustomMaterialModule,
        LimitToPipe,
        EnumPipe,
        ConfirmDialogComponent,
        ContentPlaceholderAnimationComponent,
        LocalDatePipe,
        YesNoPipe,
        UpdateStatusDialogComponent,
        FetchEntityDialogComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        AlertEntityDialogComponent,
        AlertActionEntityDialogComponent,
        ActionNotificationComponent,
        ConfirmSelectEntityDialogComponent,
        ModalPasswordDialogComponent,
        ConfirmInputEntityDialogComponent,
        ModalCropDialogComponent,
        LoadingScreenComponent,
        ConfirmFileEntityDialogComponent,
        TagDialogComponent,
        CustomImageCropperDialogComponent
    ],
    entryComponents: [
        UpdateStatusDialogComponent,
        FetchEntityDialogComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        AlertEntityDialogComponent,
        AlertActionEntityDialogComponent,
        ActionNotificationComponent,
        ConfirmSelectEntityDialogComponent,
        ModalPasswordDialogComponent,
        ConfirmInputEntityDialogComponent,
        ModalCropDialogComponent,
        LoadingScreenComponent,
        ConfirmFileEntityDialogComponent,
        TagDialogComponent,
        CustomImageCropperDialogComponent
    ]
})
export class SharedModule { }
