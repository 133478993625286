import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { Observable, Observer } from 'rxjs';
import { LayoutUtilsService } from 'src/app/core/services/layout-utils.service';

@Component({
    selector: 'app-image-cropper-dialog',
    templateUrl: './image-cropper-dialog.component.html',
})

export class CustomImageCropperDialogComponent implements OnInit, OnDestroy {

    public imageChangedEvent: any = '';
    public croppedImage: any = '';
    public imageUrlPath: any = undefined;
    public imageExtension: string = 'png';
    public defaultImage: string = 'assets/images/user.png';

    constructor(public dialogRef: MatDialogRef<CustomImageCropperDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private layoutUtilService: LayoutUtilsService, private translate: TranslateService) {
        const dataUriRegex = /^data:(.*?)(;base64)?,(.*)$/;
        if (!dataUriRegex.test(data.image)) {
            this.getBase64ImageFromFile(data.image);
        }
        else {
            this.imageUrlPath = data.image;
        }
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }

    fileChangeEvent(event: any): void {
        const file: File = event.target.files[0];
        if (file) {
            const fileSize = file.size; // Get the file size in bytes
            const maxSizeInBytes = 1024 * 1024 * 5; // 1 MB (you can adjust this value)

            if (fileSize > maxSizeInBytes) {
                this.layoutUtilService.showNotification(this.translate.instant('Maximum size allowed is 5 MB'), 'Dismiss');
                event.target.value = ''; // Clear the input to allow selecting a new file
            } else {
                this.imageChangedEvent = event;
            }
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded(image?: LoadedImage) {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onYesClick(image: any): void {
        this.dialogRef.close(image);
    }

    public getBase64ImageFromFile(imageUrl: any) {
        this.getBase64ImageFromURL(imageUrl).subscribe((base64data: any) => {
            // this is the image as dataUrl
            this.imageUrlPath = 'data:image/' + this.imageExtension + ';base64,' + base64data;
        },
            (err: any) => {
                // this.layoutUtilsService.showNotification(this.translate.instant('Error:') + ' ' + this.translate.instant('The image is not accessible'), this.translate.instant('Dismiss'));
            }
        );
    }

    getBase64ImageFromURL(url: string) {
        return Observable.create((observer: Observer<string>) => {
            // create an image object
            let img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url + '?t=' + new Date().getTime();
            if (!img.complete) {
                // This will call another method that will create image from url
                img.onload = () => {
                    observer.next(this.getBase64Image(img));
                    observer.complete();
                };
                img.onerror = (err) => {
                    observer.error(err);
                };
            } else {
                observer.next(this.getBase64Image(img));
                observer.complete();
            }
        });
    }

    getBase64Image(img: HTMLImageElement) {
        // We create a HTML canvas object that will create a 2d image
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx: any = canvas.getContext("2d");
        // This will draw image
        ctx.drawImage(img, 0, 0);
        // Convert the drawn image to Data URL
        var dataURL = canvas.toDataURL("image/" + this.imageExtension, 1);
        return dataURL.replace(/^data:image\/(png|jpg|jpeg|gif);base64,/, "");
    }
}