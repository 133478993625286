import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ApexAxisChartSeries, ApexNonAxisChartSeries, ApexChart, ApexXAxis, ApexTitleSubtitle, ApexGrid, ApexPlotOptions, ApexResponsive } from "ng-apexcharts";
import { LayoutUtilsService } from "src/app/core/services/layout-utils.service";
import { RequestService } from "src/app/core/services/request.service";

export type ChartOptions = {
    series: ApexAxisChartSeries;
    pieSeries: ApexNonAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    title: ApexTitleSubtitle;
    grid: ApexGrid;
    plotOptions: ApexPlotOptions;
    labels: any;
    responsive: ApexResponsive[];
};

@Component({
    selector: 'app-action-outcomes-chart',
    templateUrl: './action-outcomes-chart.component.html',
    styleUrls: ['./action-outcomes-chart.component.scss']
})
export class ActionOutcomesChartComponent implements OnInit, OnDestroy {

    public pieChartOptions!: any;
    public loading: boolean = false;

    @Input() actionTypes: any = [];
    @Input() memberId: string = '';
    @Input() token: string = '';
    @Input() orgId: string = '';

    constructor(private requestService: RequestService, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService) { }

    ngOnInit(): void {
        this.getActions();
    }

    ngOnDestroy(): void {

    }

    private getActions() {
        this.loading = true;

        this.requestService.getRecordByToken('report?organizationId=' + (this.orgId ? this.orgId : this.requestService.orgId) + '&homelessId=' + this.memberId, 'actions', this.token ? this.token : this.requestService.getToken(), (data, error) => {
            if (data?.results?.length) {
                let series: any = [];
                let dataLabels: any = [];
                data.results.forEach((element: any) => {
                    if (this.actionTypes.find((i: any) => i.value === element.action._id)) {
                        series.push(element.count);
                        dataLabels.push(this.actionTypes.find((i: any) => i.value === element.action._id).displayName);
                    }
                });

                this.pieChartOptions = {
                    pieSeries: series,
                    chart: {
                        type: "pie"
                    },
                    labels: dataLabels,
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200
                                },
                                legend: {
                                    position: "bottom"
                                }
                            }
                        }
                    ]
                };
            }
            if (error) {
                this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
            }
            this.loading = false;
        });
    }
}