import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { RequestService } from '../services/request.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
        private requestService: RequestService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const requiredRole = route.data['role'] as string;
        const user = this.requestService.currentUserSubject.getValue();
        // console.log(user)
        let currentUserRole = this.requestService.getUserRoleByUserData(user);

        if (user) {
            if (requiredRole === 'center' && (currentUserRole === 'admin' || currentUserRole === 'shelter'))
                return true;
            else if (requiredRole === 'responder' && (currentUserRole === 'admin' || currentUserRole === 'responder'))
                return true;
        }
        // if (user && user.expiration) {

        //     if (moment() < moment(user.expiration)) {
        //         return true;
        //     } else {
        //         this.notificationService.openSnackBar('Your session has expired');
        //         this.router.navigate(['auth/login']);
        //         return false;
        //     }
        // }

        this.router.navigate(['auth/login']);
        return false;
    }
}
