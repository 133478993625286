import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { RequestService } from '../services/request.service';

@Injectable()
export class AdminGuard implements CanActivate {

    constructor(private router: Router,
        private requestService: RequestService) { }

    canActivate() {
        const user = this.requestService.currentUserSubject.getValue();

        if (user && user.isAdmin) {
            return true;

        } else {
            this.router.navigate(['/']);
            return false;
        }
    }
}
