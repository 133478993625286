// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
//  The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  orgId: '5e85c5820c22d9223a15d489',
  serverUrl: 'https://staging.api.humanehub.interactivelife.me/homeless/',
  casUrl: 'https://staging.api.humanehub.interactivelife.me/homeless/',
  productId: 'e36fa987-5fe2-44c6-9757-52d88e8fea09',
  customKeys: {
    roleAdmin: '5eb137f1d3123a407c7c97cc',
    roleResponder: '5ee761c8d3123a0ef0d8cb61',
    roleShelter: '64228bc9bcdf4f16977daac5'
  },
  lockSite: false,
  rollbar: {
    apiKey: '42a79665d6fe42ccbe99c7dc7fa92536'
  },
  environment: 'staging',
  firebaseVapidKey: 'BOmalx8PfDKiV4h8iiLAeDVBiZN5p1LAT_GEwAgPzX-dS3I94q3MYVdjgLL4JU-FS7hD-30jDNPqXYKkYFOn1jo',
  firebaseConfig: {
    apiKey: "AIzaSyCy2m1Vg5xpdkf39sBRQ3UMrR0F6ojU9Us",
    authDomain: "humanehub-01.firebaseapp.com",
    projectId: "humanehub-01",
    storageBucket: "humanehub-01.appspot.com",
    messagingSenderId: "599788822494",
    appId: "1:599788822494:web:857f41fd90ec18fc99874f",
    measurementId: "G-D0FPL5SRW7"
  },
  aliveAppIntervalMinutes: 30, // if you keep app inactive
  aliveCheckAppIntervalMinutes: 60, // if you keep app inactive pop a message
};
