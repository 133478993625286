import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { SpinnerService } from '../../core/services/spinner.service';
import { NavigationEnd, Router } from '@angular/router';
import { FirebaseHandlersService } from 'src/app/core/services/firebase-handlers.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { RequestService } from 'src/app/core/services/request.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as moment from 'moment';
import { PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {

    // private _mobileQueryListener: () => void;
    // mobileQuery: MediaQueryList;
    isTablet: boolean = false;
    isMobile: boolean = false;
    showSpinner: boolean = false;
    userName: string = "";
    isAdmin: boolean = false;
    currentRoute: string = '';
    now = new Date();
    profileImage: string = '';
    currentNotification: any = undefined;
    notificationTimer: any = undefined;
    currentUser: any = undefined;
    showNotificationUnRead: boolean = false;
    isDnd: boolean = true;

    private subscriptions: any[] = <any>[];
    // private autoLogoutSubscription: Subscription = new Subscription;

    constructor(private changeDetectorRef: ChangeDetectorRef, private deviceService: DeviceDetectorService,
        public spinnerService: SpinnerService, private requestService: RequestService, private loadService: LoaderService,
        private router: Router, private firebaseHandler: FirebaseHandlersService, private notificationService: NotificationService) {
        this.isTablet = this.deviceService.isTablet();
        this.isMobile = this.deviceService.isMobile();
        // initializeApp(environment.firebaseConfig);
        // this.mobileQuery = this.media.matchMedia('(max-width: 1000px)');
        // this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        // this.mobileQuery.addListener(this._mobileQueryListener);

        router.events.subscribe(data => {
            if (data instanceof NavigationEnd)
                this.currentRoute = data.url;
        });
    }

    ngOnInit(): void {
        // this.fbHandler.initialize();
        // this.firebaseHandler.topic = 'notification';
        // this.fbHandler.requestPermission(this.fbHandler.topic);

        // this.requestService.getMe((data, error) => {
        //     if (data) {
        //         this.notificationService.dnd.next(data.dnd);
        //         if (!data.dnd) {
        //             this.firebaseHandler.registerDevice(this.currentUser._id);
        //             // this.firebaseHandler.requestPermission(this.firebaseHandler.topic);
        //         }
        //     }
        // });

        this.subscriptions.push(this.notificationService.dnd.subscribe(async (isDnd) => {
            this.isDnd = isDnd;
        }));

        this.subscriptions.push(this.requestService.currentUserSubject.subscribe((user) => {
            if (user) {
                this.currentUser = user;

                if (!user) {
                    setTimeout(() => {
                        this.requestService.logout();
                    }, 200);
                }
                else {
                    this.isAdmin = user.isAdmin;
                    this.userName = user.name;
                    this.profileImage = user.pictureLink || 'assets/images/user.png';
                }
            }
        }));

        this.subscriptions.push(this.notificationService.showNotificationUnRead.subscribe((show: any) => {
            if (this.currentRoute !== '/notifications' || !show)
                this.showNotificationUnRead = show;
        }));

        this.subscriptions.push(this.firebaseHandler.currentMessage.subscribe((message: any) => {
            // console.log('message', message)
            if (message && message.data?.notification?.body) {
                // let notification: any = JSON.parse(message.notification.body);
                // this.currentNotification = this.notificationService.generateNotificationText(notification.type, notification.action?._id, notification.officer_id, notification.homeless_id, notification.hospital_id, notification.shelter_id, notification.count);
                this.notificationService.showNotificationUnRead.next(true);
                this.notificationService.refreshData.next(true);
                if (!this.isDnd) {
                    // let notification: any = JSON.parse(message.notification.body.notification.message);
                    this.currentNotification = message.data.notification.body;
                    // this.currentNotification = this.notificationService.generateNotificationText(notification.type, notification.action?._id, notification.officer_id, notification.homeless_id, notification.hospital_id, notification.shelter_id, notification.count);
                    // this.currentNotification = JSON.parse(message.notification.body);
                    if (this.notificationTimer) {
                        clearTimeout(this.notificationTimer);
                        this.notificationTimer = undefined;
                    }
                    this.notificationTimer = setTimeout(() => this.currentNotification = null, 10000);
                }
            }
            else
                this.currentNotification = null;
        }));

        this.getNotifications();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
        // tslint:disable-next-line: deprecation
        // this.mobileQuery.removeListener(this._mobileQueryListener);
        // this.autoLogoutSubscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    private getNotifications() {
        this.notificationService.getNotifications('', 0, 1).then((data: any) => {
            if (localStorage.getItem('last-seen-notifications')) {
                let lastSeen = moment(Number(localStorage.getItem('last-seen-notifications'))).utc();
                if (lastSeen && lastSeen.isBefore(moment(data.results[0]?.createdAt))) {
                    this.showNotificationUnRead = true;
                }
            }
        }).catch((error: any) => {

        }).finally(() => {

        });
    }

    closeNotification() {
        this.currentNotification = null;
    }

    // onLoggedout() {
    //     this.loadService.display(true);
    //     this.requestService.logOutApi();
    // }

    logout() {
        this.loadService.display(true);
        this.firebaseHandler.logout();
    }
}
