import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { RequestService } from './request.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    public showNotificationUnRead: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public dnd: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public refreshData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showShelterNotification: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

    constructor(private snackBar: MatSnackBar, private requestService: RequestService) { }

    public openSnackBar(message: string) {
        this.snackBar.open(message, '', {
            duration: 5000
        });
    }

    public generateNotificationText(type: string, actionId: string, officerId: any, homelessId: any, hospitalId: any, shelterId: any, count: number = 0, status: string): string {
        let text = '';
        switch (type) {
            case 'homeless':
                let homelessName = homelessId?.name;
                if (homelessId?.firstName)
                    homelessName = (homelessId?.firstName ? homelessId.firstName.charAt(0) + '.' : '') + ' ' + homelessId.lastName;
                text = officerId.rank.name + ' ' + officerId.lastName + ' registered a new community member ' + homelessName;
                break;
            case 'action':
                if (actionId === '2') //hospital
                    text = 'Community member ' + homelessId.name + ' transported to ' + hospitalId.name;
                else if (actionId === '1') { //shelter                        
                    if (status == 'accepted')
                        text = 'Approved admittance request for ' + homelessId.name + ' by ' + shelterId.name;
                    else
                        text = 'Community member ' + homelessId.name + ' sent to ' + shelterId.name;
                }
                break;
            case 'encampment':
                text = count + ' new encampments were reported today';
                break;
            case 'resource':
                text = count + ' community members received resources today';
                break;
        }
        return text;
    }

    // public generateShelterNotification(homelessName: any, beds_remaining: string) {
    //     return 'Admittance request for ' + homelessName + ' ' + beds_remaining + ' beds remaining.';
    // }

    getNotifications(term: string, pageIndex: number, pageSize: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getDataList('notify', {
                term: term || '', termfields: ['homeless_id.name', 'officer_id.name', 'shelter_id.name', 'type', 'hospital_id.name'], page: pageIndex + 1, perpage: pageSize, filter: { "$and": [{ "organizationId._id": { "$eq": this.requestService.orgId } }] }, orderBy: 'createdAt', orderDir: 'desc', fieldKeys: ['name', 'createdAt', 'homeless_id', 'hospital_id', 'foodbank_id', 'officer_id', 'shelter_id', 'action', 'count', 'isGrouped', 'flags', 'status',
                    'type']
            }, (data: any, error: any) => {
                if (data) {
                    resolve(data);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }
}
