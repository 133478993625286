import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

@Injectable()
export class UserActivityService {
  constructor() {
    this.clearOldActivities();
    this.getUserActivity();
  }
  private clearOldActivities() {
    let keyList = [];
    for (var i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i)!.indexOf('-userActivity') > -1) {
        let activity = JSON.parse(localStorage.getItem(localStorage.key(i)!)!);
        if (activity.hasOwnProperty('initiated')) {
          let currentDate: Moment = moment.utc();
          let logTime: Moment = moment.utc(activity['initiated']).local();
          if (currentDate.diff(logTime, 'days') > 2) {
            keyList.push(localStorage.key(i));
          }
        } else {
          keyList.push(localStorage.key(i));
        }
      }
    }
    for (let ky of keyList) {
      localStorage.removeItem(ky!);
    }
  }
  public getLogs(): any[] {
    let userActivity: any = this.getUserActivity();
    if (userActivity.hasOwnProperty('logs'))
      return userActivity['logs'];
    else {
      return [];
    }
  }
  getUserActivity() {
    let userActivity: any = {};
    if (localStorage.getItem('currentUser') && localStorage.getItem('o')) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser')!);
      let organizationId = JSON.parse(localStorage.getItem('o')!);
      if (currentUser.hasOwnProperty('_id')) {
        if (localStorage.getItem(organizationId + '-' + currentUser._id + '-userActivity')) {
          userActivity = JSON.parse(localStorage.getItem(organizationId + '-' + currentUser._id + '-userActivity') || '');
          if (userActivity.hasOwnProperty('logs')) {
            let currentDate: Moment = moment.utc();
            let logs = userActivity['logs'].filter((log: any) => {
              let logTime: Moment = moment.utc(log.time).local();
              if (currentDate.diff(logTime, 'days') < 2) {
                return true;
              }
              return false;
            });
            logs = logs.slice(0, 100);
            userActivity['logs'] = logs;
          }
        } else {
          userActivity = { initiated: moment.utc(), _id: currentUser._id, o: organizationId, logs: [] };
          localStorage.setItem(organizationId + '-' + currentUser._id + '-userActivity', JSON.stringify(userActivity));
        }
      }
    }
    return userActivity;
  }
  clearActivityLogs(organizationId: any, currentUser: any) {
    if (currentUser.hasOwnProperty('_id')) {
      let userActivity = { initiated: moment.utc(), _id: currentUser._id, o: organizationId, logs: [] };
      localStorage.setItem(organizationId + '-' + currentUser._id + '-userActivity', JSON.stringify(userActivity));
    }
  }
  public insertLog(type: string, func: string, message: string = '', variables: any = undefined, apiCall: string = '') {
    let userActivity = this.getUserActivity();
    if (userActivity.hasOwnProperty('_id') && userActivity.hasOwnProperty('o') && userActivity.hasOwnProperty('logs')) {
      let dataRow = { time: moment.utc(), type: type, func: func, message: message, apiCall: apiCall, variables: variables };
      userActivity['logs'].unshift(dataRow);
      localStorage.setItem(userActivity['o'] + '-' + userActivity['_id'] + '-userActivity', JSON.stringify(userActivity));
    }
  }
}
